// @mui material components
import {
    Grid,
    TextField,
    Stack,
    CircularProgress,
    Button,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel, Backdrop, Typography, InputLabel, Select, MenuItem
} from "@mui/material";
//components
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import CustomMapPicker from "./CustomMapPicker";
import React, {useState} from "react";
import {TimePicker} from "@mui/x-date-pickers";
import moment from "moment";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as api from '../apiServices/apiServices';

function Forms() {
    
    const [checkBoxes, setCheckBoxes] = useState({
        gender: 'male',
        type: "femaleOnly",
        workingDaysPerWeek: '05',
    });
    const [pickupTime, setPickupTime] = useState(moment(new Date()));
    const [dropoffTime, setDropoffTime] = useState(moment(new Date()));
    const [pickupLocation, setPickupLocation] = useState(null);
    const [dropoffLocation, setDropoffLocation] = useState(null);
    const [pickupMapOpen, setPickupMapOpen] = useState(false);
    const [dropoffMapOpen, setDropoffMapOpen] = useState(false);
    const [requestSubmitedDailog, setRequestSubmitedDailog] = useState(false);
    const [intrestedDailog, setIntrestedDailog] = useState(false);
    const [notIntrestedDailog, setNotIntrestedDailog] = useState(false);
    const [response, setResponse] = useState(null);
    const [ errorDialog, setErrorDialog]=useState(false);
    const [formErrors, setFormErrors] = useState({
        pickup: false,
        dropoff: false
    });


    const handleCheckBoxesChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setCheckBoxes({
            ...checkBoxes,
            [name]: value
        });
    }

    const schema = Yup.object().shape({
        name: Yup.string().min(3).required('Required'),
        phoneNumber: Yup.string()
            .min(11).max(11)
            .required('A phone number is required'),
        cnic: Yup.string().min(13).max(13).required('Required'),
        pickupTime: Yup.string().required('Required'),
        dropoffTime: Yup.string().required('Required'),
        pickupLocationAddress: Yup.string().required('Required'),
        dropoffLocationAddress: Yup.string().required('Required')
    });
    const formik = useFormik({
        initialValues: {
            name: '',
            phoneNumber: '',
            cnic: '',
            pickupTime: '',
            dropoffTime: '',
            pickupLocationAddress: '',
            dropoffLocationAddress: ''
        },
        enableReinitialize: true,
        validationSchema: schema,
        onSubmit: async (values) => {
            console.log({pickupLocation, dropoffLocation}, !pickupLocation);

            let errors = {};
            if (!pickupLocation) {
                errors = {
                    ...errors,
                    pickup: true
                }
            }
            if (!dropoffLocation) {
                errors = {
                    ...errors,
                    dropoff: true
                }
            }
            setFormErrors({
                ...formErrors,
                ...errors
            })
            let data = {
                "passenger_id": 2513,
                "otp": "1234",
                "name": values.name,
                "phone": values.phoneNumber,
                "cnic": values.cnic,
                "gender": checkBoxes.gender,
                "type": checkBoxes.type,
                "working_days_per_week": checkBoxes.workingDaysPerWeek,
                "pickup_place_name": values.pickupLocationAddress,
                "pickup_latitude": pickupLocation.position.lat,
                "pickup_longitude": pickupLocation.position.lng,
                "dropoff_place_name": values.dropoffLocationAddress,
                "dropoff_latitude": dropoffLocation.position.lat,
                "dropoff_longitude": dropoffLocation.position.lng,
                "office_start_time": values.pickupTime,
                "office_end_time": values.dropoffTime,
                "fare": 1,
                "active": "1"
            }
            let res = await api.sendData(data);
            if (res.error == 0) {
                let data = res.data.passengerPickAndDropServiceDetails;
                setResponse(data);
                setRequestSubmitedDailog(true);
            }
            if (res.error != 0)
            {
                let error= res.message;
                setResponse(error);
                setErrorDialog(true);
            }
            
            return true;
        }
    });
    async function updateInterest (interest){
        let res = await api.updateInterest({
            "interested": interest
            }, response.id);

            console.log(res);
    }
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
    function handleClose(){
        setErrorDialog(false);
    }
    return (
        <FormikProvider value={formik}>
            {/*<CustomMapPicker />*/}
            <div id="container" >
                <div className="overlayBackground" >
                    <div className="container mx-auto">
                        <div className={`max-w-screen-md mx-auto`}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <div className="flex flex-col mx-5 md:mx-0 text-white space-y-5 pb-20">
                                    <div>
                                        <p className="text-2xl text-center mb-8 mt-2 border-b" >
                                            Pick and Drop Form
                                        </p>
                                    </div>
                                    <div>
                                        <p className="mb-5" >
                                            PSBS is soon starting its regular Pick and Drop service
                                            for daily commuters. If you are interested, please fill out following information and our representative
                                            will call to align you with our service.
                                        </p>
                                        <p className="mb-10 text-right" >
                                            ہم (PSBS) جلد ہی روزانہ مسافروں کے لیے اپنی باقاعدہ پک اینڈ ڈراپ سروس شروع کر رہے ہیں۔ اگر آپ دلچسپی رکھتے ہیں، تو براہ کرم درج ذیل معلومات پر کریں اور ہمارا نمائندہ آپ کو ہماری سروس سے آگاہ کرنے کے لیے کال کرے گا۔
                                        </p>
                                    </div>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        color="primary"
                                        autoComplete="name"
                                        label="Full name *"
                                        className="bg-white"
                                        {...getFieldProps('name')}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        autoComplete="phone"
                                        label="Phone number *"
                                        className="bg-white"
                                        {...getFieldProps('phoneNumber')}
                                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                        helperText={touched.phoneNumber && errors.phoneNumber}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        color="secondary"
                                        autoComplete="cnic"
                                        label="CNIC *"
                                        className="bg-white"
                                        {...getFieldProps('cnic')}
                                        error={Boolean(touched.cnic && errors.cnic)}
                                        helperText={touched.cnic && errors.cnic}
                                    />

                                    <FormControl>
                                        <FormLabel style={{ color: 'white' }} >Gender</FormLabel>
                                        <RadioGroup
                                            row
                                            name="gender"
                                            value={checkBoxes.gender}
                                            onChange={handleCheckBoxesChange}
                                        >
                                            <FormControlLabel value="female" control={
                                                <Radio
                                                    style={{color:"#979797"}}
                                                    className="bg-white"
                                                />
                                            } label="Female" />
                                            <FormControlLabel value="male" control={
                                                <Radio
                                                    style={{color:"#979797"}}
                                                    className="bg-white"
                                                />
                                            } label="Male" />
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel style={{ color: 'white' }}  >Preferred Group Type</FormLabel>
                                        <RadioGroup
                                            row
                                            name="type"
                                            value={checkBoxes.type}
                                            onChange={handleCheckBoxesChange}
                                        >
                                            <FormControlLabel value="femaleOnly" control={
                                                <Radio
                                                    style={{color:"#979797"}}
                                                    className="bg-white"
                                                />
                                            } label="Female only" />
                                            <FormControlLabel value="combined" control={
                                                <Radio
                                                    style={{color:"#979797"}}
                                                    className="bg-white"
                                                />
                                            } label="Combined" />
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel style={{ color: 'white' }}  >Number of Working days per week </FormLabel>
                                        <RadioGroup
                                            row
                                            name="workingDaysPerWeek"
                                            value={checkBoxes.workingDaysPerWeek}
                                            onChange={handleCheckBoxesChange}
                                        >
                                            <FormControlLabel value="05" control={
                                                <Radio
                                                    style={{color:"#979797"}}
                                                    className="bg-white"
                                                />
                                            } label="05" />
                                            <FormControlLabel value="06" control={
                                                <Radio
                                                    style={{color:"#979797"}}
                                                    className="bg-white"
                                                />
                                            } label="06" />
                                        </RadioGroup>
                                    </FormControl>

                                    <div className={`flex flex-col space-y-2  ${formErrors.pickup ? "border border-red-500 p-2 rounded" : ''}`} >
                                        <p>Pickup Address {formErrors.pickup ? "border border-red-500 p-2 rounded" : ''} </p>
                                        {/*<div className="bg-white text-black rounded p-2 " >*/}
                                        {/*    /!*{(!pickupLocation || (pickupLocation && !pickupLocation.address)) && "No Address Selected"}*!/*/}
                                        {/*    /!*{(pickupLocation && pickupLocation.address) || ''}*!/*/}
                                        {/*</div>*/}
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            autoComplete="pickupLocationAddress"
                                            label="Pickup address *"
                                            className="bg-white"
                                            {...getFieldProps('pickupLocationAddress')}
                                            error={Boolean(touched.pickupLocationAddress && errors.pickupLocationAddress)}
                                            helperText={touched.pickupLocationAddress && errors.pickupLocationAddress}
                                        />
                                        <div className="bg-white text-black rounded p-2 flex flex-col items-center justify-center py-10 cursor-pointer" onClick={() => { setPickupMapOpen(true); }} >
                                            <img src="/images/mapMarker.png" className="w-8" />
                                            {
                                                pickupLocation && pickupLocation.position && (
                                                   "Latitude: "+pickupLocation.position.lat + ", Longitute: "+ pickupLocation.position.lng
                                                )
                                            }
                                            {
                                                (!pickupLocation || !pickupLocation.position) && (
                                                    "Select Pin Location"
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className={`flex flex-col space-y-2 ${formErrors.dropoff ? "border border-red-500 p-2 rounded" : ''}`}  >
                                        <p>Drop-off Address</p>
                                        {/*<div className="bg-white text-black rounded p-2" >*/}
                                        {/*    {(!dropoffLocation || (dropoffLocation && !dropoffLocation.address)) && "No Address Selected"}*/}
                                        {/*    {(dropoffLocation && dropoffLocation.address) || ''}*/}
                                        {/*</div>*/}
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            autoComplete="dropoffLocationAddress"
                                            label="Dropoff address *"
                                            className="bg-white"
                                            {...getFieldProps('dropoffLocationAddress')}
                                            error={Boolean(touched.dropoffLocationAddress && errors.dropoffLocationAddress)}
                                            helperText={touched.dropoffLocationAddress && errors.dropoffLocationAddress}
                                        />
                                        <div className="bg-white text-black rounded p-2 flex flex-col items-center justify-center py-10 cursor-pointer" onClick={() => { setDropoffMapOpen(true) }}  >
                                            <img src="/images/mapMarker.png" className="w-8" />
                                            {
                                                dropoffLocation && dropoffLocation.position && (
                                                    "Latitude: "+dropoffLocation.position.lat + ", Longitute: "+ dropoffLocation.position.lng
                                                )
                                            }
                                            {
                                                (!dropoffLocation || !dropoffLocation.position) && (
                                                    "Select Pin Location"
                                                )
                                            }
                                        </div>
                                    </div>

                                    <FormControl
                                        fullWidth
                                        variant="filled"
                                        color="secondary"
                                        className="bg-white"
                                    >
                                        <InputLabel id="pickupTime">Office Start Time *</InputLabel>
                                        <Select
                                            labelId="pickupTime"
                                            id="pickupTime"
                                            label="Office Start Time *"
                                            {...getFieldProps('pickupTime')}
                                            error={Boolean(touched.pickupTime && errors.pickupTime)}
                                            helperText={touched.pickupTime && errors.pickupTime}
                                        >
                                            <MenuItem value={"06:00 AM"}>06:00 AM</MenuItem>
                                            <MenuItem value={"06:30 AM"}>06:30 AM</MenuItem>
                                            <MenuItem value={"07:00 AM"}>07:00 AM</MenuItem>
                                            <MenuItem value={"07:30 AM"}>07:30 AM</MenuItem>
                                            <MenuItem value={"08:00 AM"}>08:00 AM</MenuItem>
                                            <MenuItem value={"08:30 AM"}>08:30 AM</MenuItem>
                                            <MenuItem value={"09:00 AM"}>09:00 AM</MenuItem>
                                            <MenuItem value={"09:30 AM"}>09:30 AM</MenuItem>
                                            <MenuItem value={"10:00 AM"}>10:00 AM</MenuItem>
                                            <MenuItem value={"10:30 AM"}>10:30 AM</MenuItem>
                                            <MenuItem value={"11:00 AM"}>11:00 AM</MenuItem>
                                            <MenuItem value={"11:30 AM"}>11:30 AM</MenuItem>
                                            <MenuItem value={"12:00 AM"}>12:00 AM</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        variant="filled"
                                        color="secondary"
                                        className="bg-white"
                                    >
                                        <InputLabel id="dropoffTime">Office End Time *</InputLabel>
                                        <Select
                                            fullWidth
                                            variant="filled"
                                            color="secondary"
                                            className="bg-white"
                                            labelId="dropoffTime"
                                            id="dropoffTime"
                                            label="Office End Time *"
                                            {...getFieldProps('dropoffTime')}
                                            error={Boolean(touched.dropoffTime && errors.dropoffTime)}
                                            helperText={touched.dropoffTime && errors.dropoffTime}
                                        >
                                            <MenuItem value={"01:00 PM"}>01:00 PM</MenuItem>
                                            <MenuItem value={"01:30 PM"}>01:30 PM</MenuItem>
                                            <MenuItem value={"02:00 PM"}>02:00 PM</MenuItem>
                                            <MenuItem value={"02:30 PM"}>02:30 PM</MenuItem>
                                            <MenuItem value={"03:00 PM"}>03:00 PM</MenuItem>
                                            <MenuItem value={"03:30 PM"}>03:30 PM</MenuItem>
                                            <MenuItem value={"04:00 PM"}>04:00 PM</MenuItem>
                                            <MenuItem value={"04:30 PM"}>04:30 PM</MenuItem>
                                            <MenuItem value={"05:00 PM"}>05:00 PM</MenuItem>
                                            <MenuItem value={"05:30 PM"}>05:30 PM</MenuItem>
                                            <MenuItem value={"06:00 PM"}>06:00 PM</MenuItem>
                                            <MenuItem value={"06:30 PM"}>06:30 PM</MenuItem>
                                            <MenuItem value={"07:00 PM"}>07:00 PM</MenuItem>
                                            <MenuItem value={"07:30 PM"}>07:30 PM</MenuItem>
                                            <MenuItem value={"08:00 PM"}>08:00 PM</MenuItem>
                                            <MenuItem value={"08:30 PM"}>08:30 PM</MenuItem>
                                            <MenuItem value={"09:00 PM"}>09:00 PM</MenuItem>
                                            <MenuItem value={"09:30 PM"}>09:30 PM</MenuItem>
                                            <MenuItem value={"10:00 PM"}>10:00 PM</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {/*<TimePicker*/}
                                    {/*    label="Office Start Time"*/}
                                    {/*    value={pickupTime}*/}
                                    {/*    className="bg-white"*/}
                                    {/*    onChange={(a) => { setPickupTime(a) }}*/}
                                    {/*    renderInput={(params) => <TextField variant="filled" {...params} />}*/}
                                    {/*/>*/}
                                    {/*<TimePicker*/}
                                    {/*    label="Office End Time"*/}
                                    {/*    value={dropoffTime}*/}
                                    {/*    className="bg-white text-black"*/}
                                    {/*    onChange={(a) => { setDropoffTime(a) }}*/}
                                    {/*    renderInput={(params) => <TextField variant="filled" {...params} />}*/}
                                    {/*/>*/}

                                    {/*<FormControlLabel*/}
                                    {/*    control={*/}
                                    {/*        <Checkbox*/}
                                    {/*            name="gender"*/}
                                    {/*            checked={checkBoxes.gender}*/}
                                    {/*            onChange={handleCheckBoxesChange}*/}
                                    {/*        />*/}
                                    {/*    }*/}
                                    {/*    label="Gender"*/}
                                    {/*/>*/}

                                    <div>
                                        <p className="mb-2 mt-10" >
                                            * Note: This service is for monthly basis, hence the estimated fare will be calculated for a month's equivalent service.
                                        </p>
                                        <p className="mb-15 text-right" >
                                            *نوٹ: یہ سروس ماہانہ بنیادوں پر ہے، اس لیے تخمینی کرایہ ایک ماہ کی مساوی سروس کے لیے شمار کیا جائے گا۔
                                        </p>
                                    </div>

                                    <div className="flex flex-row items-end justify-center mb-15 mt-15 ">
                                        {
                                            isSubmitting && (
                                                <Backdrop
                                                    sx={{ color: '#ffffff', zIndex: 99999999999 }}
                                                    open={true}
                                                >
                                                    <div className="bg-white rounded p-5 px-20" >
                                                        <div style={{ marginBottom: 10, textAlign: "center" }} >
                                                            <CircularProgress color="primary" />
                                                        </div>
                                                        <Typography className="text-black" >
                                                            Loading...
                                                        </Typography>
                                                    </div>
                                                </Backdrop>
                                            )
                                        }
                                        {
                                            !isSubmitting && (
                                                <>
                                                    <Button
                                                        variant={"contained"}
                                                        color={"info"}
                                                        fullWidth
                                                        size="large"
                                                        onClick={handleSubmit}
                                                    >
                                                        CALCULATE FARE
                                                    </Button>

                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                open={pickupMapOpen}
                fullScreen
            >
                <DialogTitle>
                    Choose Pickup Location
                </DialogTitle>
                <DialogContent>
                    <div>
                        <CustomMapPicker 
                            data={pickupLocation} 
                            onClose={() => { setPickupMapOpen(false); }} 
                            onChoose={(s) => { 
                                setPickupMapOpen(false); 
                                setFormErrors({ ...formErrors, pickup: false });
                                setPickupLocation(s) }}  />
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={dropoffMapOpen}
                fullScreen
            >
                <DialogTitle>
                    Choose Drop-off Location
                </DialogTitle>
                <DialogContent>
                    <div>
                        <CustomMapPicker data={dropoffLocation} onClose={() => { setDropoffMapOpen(false); }} onChoose={(s) => { setDropoffMapOpen(false); setFormErrors({ ...formErrors, dropoff: false }); setDropoffLocation(s) }}  />
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog
                open={errorDialog}
                onClose={handleClose}
            >
                <DialogContent>
                <Typography variant={"h5"} style={{color:"red"}}>
                    Error:{(response)}
                    </Typography>
                </DialogContent>
            </Dialog>
            <Dialog
                open={requestSubmitedDailog}
            >
                <DialogContent>
                    <Typography variant={"h5"}>
                        Request Submitted
                    </Typography>
                    <Typography variant={"p"}>
                        Thank you for showing interest.
                    </Typography>
                    <Typography variant={"h6"} sx={{marginTop: 3}} >
                        Your Pick N'Drop request has been submitted successfully. Your estimated monthly charges for above submitted details will be:
                    </Typography>
                    <Typography variant={"h5"} sx={{marginTop: 4, marginBottom: 4}} >
                        Rs. {(response && response.fare_for_pickup_point_and_drop_service)}
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }} >
                        <Button sx={{color: 'seagreen', fontSize: 20}} onClick={() => { updateInterest(1); setRequestSubmitedDailog(false); setIntrestedDailog(true)} } >
                            I'M INTERESTED
                        </Button>
                        <Button sx={{color: 'seagreen'}} onClick={() => { updateInterest(0); setRequestSubmitedDailog(false); setNotIntrestedDailog(true); } } >
                            MAY BE NEXT TIME
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={intrestedDailog}
            >
                <DialogContent>
                    <Typography variant={"h6"} sx={{marginTop: 3}} >
                        Our representative will contact you soon to align with our service.
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }} >
                        <Button sx={{color: 'seagreen', fontSize: 20}} onClick={() => setIntrestedDailog(false)} >
                            OK
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={notIntrestedDailog}
            >
                <DialogContent>
                    <Typography variant={"h4"} sx={{marginTop: 3}} >
                        Thank you.
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                        <Button sx={{color: 'seagreen', fontSize: 20}} onClick={() => setNotIntrestedDailog(false)} >
                            Close
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </FormikProvider>
  );
}

export default Forms;