import request from './requests';
import { apis } from './apis';

///ipAddress
export const sendData = async (data) => {
  const response = await request('post', apis.sendData, data);
  return response;
};

export const updateInterest = async (data, id) => {
  const response = await request('put', apis.updateData+id, data);
  return response;
};