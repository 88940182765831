import { Routes, Route, Link } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Forms from "./pages/Form";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from "@mui/x-date-pickers";

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// const darkTheme = createTheme({
//     palette: {
//         mode: 'dark',
//     },
// });

function App() {

  return (
      // <ThemeProvider theme={darkTheme}>
      //     <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterMoment}>
              <>
                  <Routes>
                      <Route path="/" element={<Forms />} />
                  </Routes>
              </>
              <ToastContainer
                  position="bottom-center"
                  autoClose={5000}
                  hideProgressBar
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
              />
          </LocalizationProvider>
      // </ThemeProvider>
  );
}

export default App;
