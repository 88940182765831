import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from './apis';

const request = async (method, url, data = {}, isMultiPart) => {

  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: { ['api-token']: "6afed-d443a-5c727-645e0-463ce-3d0fb-c0e4c-c0671" }
  });
  try {
    let response = null;
    if (!isMultiPart) {
      response = await axiosInstance.request({
        url,
        method,
        data
      });
    } else {
      response = await fetch(BASE_URL + url, data);
    }
    if (response.data && response.data.data.hasError) toast.error(response.data.responseMessage);
    return response.data;
  } catch (error) {
    let { message } = error;
    if (error.response && error.response.data && error.response.data.responseMessage) {
      message = error.response.data.responseMessage;
    }
    toast.error(message);
    return {
      responseMessage: message,
      hasError: true
    };
  }
};
export default request;
