import React, { Component, useEffect, useState } from 'react';
import { Map, Marker, GoogleApiWrapper, } from 'google-maps-react';
import { autocompleteClasses, Button } from "@mui/material";


export function CustomMapPicker({ google, data, onChoose, onClose }) {
    const [state, setState] = useState({
        address: "Karachi, Sindh, Pakistan",
        position: { lat: 24.860966, lng: 66.990501 }
    });
    useEffect(() => {
        if (data && data.address) {
            setState(data);
        }
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "300px" }}>
            <Map
                containerStyle={{
                    marginTop: "30px",
                    width: '82%',
                    height: '450px'
                }}
                radius={-1}
                google={google}
                zoom={10}
                initialCenter={state.position}
                onClick={(a, b, c) => {
                    setState({
                        position: {
                            lat: c.latLng.lat(),
                            lng: c.latLng.lng()
                        }
                    })
                }}
            >
                <Marker
                    position={{ lat: state.position.lat, lng: state.position.lng }}
                />
            </Map>
            <div style={{ display: "flex", flexDirection: "row",alignItems:"center",justifyContent:"space-between", marginTop: "500px" }}>
                    <Button variant="contained" color="success" onClick={() => { onChoose(state) }} >
                        OK
                    </Button>
                    <Button variant="outlined" color="warning" onClick={() => { onClose() }} >
                        CANCEL
                    </Button>
            </div>
        </div>
    )
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyAkBhTU6Tc8FNdu64ZRG4rPm2bin7H7OOI'
})(CustomMapPicker);